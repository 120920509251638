import React from "react"
import Styled from "styled-components"
import { useTable } from "react-table"

const Table = Styled.table`
  width: 70%;
  margin: 0 auto;
  padding: 0px;
  box-sizing: border-box;
  border: ${({ theme }) => `1px solid ${theme.lightGrey}`};
  border-collapse: collapse;
  font-family: ${({ theme }) => theme.robotoFont};
  @media screen and (max-width: 600px) {
    width: 90%
  }
  thead {
    tr {
      th {
        border: ${({ theme }) => `1px solid ${theme.black}`};
        cursor: pointer;
        font-weight: unset;
        padding-right: 10px;
        padding-top: 24px;
        padding-bottom: 20px;
        width: 130px;
        padding-left: 25px;
        font-size: 1rem;
        color: ${({ theme }) => theme.white};
        line-height: 1.4;
        text-transform: uppercase;
        text-align: center;
        background-color: ${({ theme }) => theme.lightGrey};
      }
    }
  }
  tbody {
    tr {
      &:nth-child(even) {
        background-color: ${({ theme }) => theme.lightGrey};
        td {
          color: ${({ theme }) => theme.white};
        }
      }
      td {
        border: ${({ theme }) => `1px solid ${theme.lightGrey}`};
        cursor: pointer;
        padding-top: 18px;
        padding-bottom: 14px;
        font-weight: unset;
        padding-right: 10px;
        font-size: 14px;
        color: ${({ theme }) => theme.lightGrey};
        line-height: 1.4;
        text-align: center;
        &:hover {
          background-color: ${({ theme }) => theme.primary};
          color: ${({ theme }) => theme.white};
        }
    }
  }
`

const MyTable = ({ columns, data }) => {
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data,
  })

  return (
    <Table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default MyTable

import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery, navigate } from "gatsby"
import { connect } from "react-redux"
import * as actions from "@redux/actions"
import Axios from "axios"

import Layout from "@components/Layout"
import SEO from "@components/Seo"
import { Button, Error } from "@components/Form"
import Loader from "@components/Loader"
import { H1, H3, HR } from "@components/StyledElements"
import PreviousCheckIns from "@components/PreviousCheckIns"

import { SERVER_URL } from "@config"
import { GetToken } from "@utils"

const ProfilePreviousCheckinsPage = ({ authenticated, ReduxLogout }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [previousCheckinData, setPreviousCheckinData] = useState(null)
  const data = useStaticQuery(graphql`
    query ProfilePreviousCheckinsQuery {
      banner: allFile(filter: { name: { eq: "client_portal_1440x400" } }) {
        edges {
          node {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            id
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (!authenticated) {
      ReduxLogout()
    }
  }, [authenticated])

  const GetCheckIns = async () => {
    const token = GetToken()
    if (token) {
      try {
        setLoading(true)
        const { data } = await Axios.get(`${SERVER_URL}/client-checkins`, {
          headers: {
            authorization: token,
          },
        })

        setPreviousCheckinData(data?.checkIns)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setPreviousCheckinData(null)
        setError(
          error?.response?.data?.message || "Client Checkins Error Occured."
        )
      }
    }
  }

  useEffect(() => {
    GetCheckIns()
  }, [])

  const banner = data.banner.edges[0].node.childImageSharp.fluid
  return (
    <Layout fluid={banner} alt={"Neil Hill Coaching"} height={300}>
      <div className="container-fluid">
        <SEO title="Client Profile | Previous Checkins" />
        <div className="row d-flex justify-content-center mt-5">
          <div className="col-12 mt-2 text-center">
            <H1>Previous Checkins</H1>
          </div>
          {previousCheckinData ? (
            <div className="col-12 mt-2 text-center">
              <PreviousCheckIns data={previousCheckinData} />
            </div>
          ) : (
            <div className="col-12 mt-2 text-center">
              <H3>No Previous Checkins Found</H3>
            </div>
          )}
          {loading && (
            <div className="col-12 mt-2 text-center">
              <Loader message={"Finding your previous checkins 💪"} />
            </div>
          )}
          {error && (
            <div className="col-12 my-3 text-center">
              <Error message={error} />
            </div>
          )}
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 mx-auto">
            <HR />
          </div>
          <div className="col-12 mt-2 text-center">
            <Button onClick={() => navigate("/app/profile")}>Go Back</Button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    authenticated: state.auth.authenticated,
    loading: state.auth.loading,
  }
}
export default connect(mapStateToProps, actions)(ProfilePreviousCheckinsPage)

import React, { useMemo } from "react"
import dayjs from "dayjs"

import MyTable from "./MyTable"

const PreviousCheckIns = ({ data }) => {
  const columns = useMemo(
    () => [
      {
        Header: `Checkins`,
        columns: [
          {
            Header: "Checkin Date",
            accessor: "dateOfCheckin",
            Cell: ({ cell: { value } }) =>
              dayjs(value).format("dddd, MMMM D, YYYY h:mm A"),
          },
          {
            Header: "Completed By Neil",
            accessor: "complete",
            Cell: ({ cell: { value } }) => (value ? "Yes" : "No"),
          },
          {
            Header: "Last Updated",
            accessor: "_updatedAt",
            Cell: ({ cell: { value } }) =>
              dayjs(value).format("dddd, MMMM D, YYYY h:mm A"),
          },
        ],
      },
    ],
    []
  )
  return <MyTable columns={columns} data={data} />
}

export default PreviousCheckIns
